// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Ph5fK_x-KrW2RsADVM_15 > div {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n\n._33bIpjW9X_wSNHspNapsh {\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  display: -webkit-box !important;\n  display: -ms-flexbox !important;\n  display: flex !important;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n\n.V-iuTTpmd9j3H-amFeeve {\n  padding: 8px;\n  max-height: 66px;\n  overflow-y: hidden;\n  text-overflow: ellipsis;\n}\n\n._29cc0FDQXta_1f8HpABirS {\n  padding: 16px;\n  width: calc(100% - 32px);\n  position: absolute;\n  text-overflow: ellipsis;\n  bottom: 0px;\n}\n", ""]);
// Exports
exports.locals = {
	"infinite": "_2Ph5fK_x-KrW2RsADVM_15",
	"title": "_33bIpjW9X_wSNHspNapsh",
	"titleInner": "V-iuTTpmd9j3H-amFeeve",
	"subtitle": "_29cc0FDQXta_1f8HpABirS"
};
module.exports = exports;
