// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1THk_Fz0Sbpjqo9monfA75 {\n  background: rgba(0, 0, 0, .50);\n  height: 100%;\n  margin: 0px;\n  overflow-y: scroll;\n  padding: 0px;\n  place-self: center;\n  position: absolute;\n  top: 0px;\n  width: 100%;\n}\n\n._1YdkqhZ0-82iJ_OBnU8skG {\n  margin: auto;\n  max-width: 65vh;\n  padding-bottom: 23vh;\n  padding-top: 50vh;\n  width: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"brushSizePickerContainer": "_1THk_Fz0Sbpjqo9monfA75",
	"brushSizePicker": "_1YdkqhZ0-82iJ_OBnU8skG"
};
module.exports = exports;
