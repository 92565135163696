// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._23iRLS1Ry0WuAwnFBuUz7y {\n  background: rgba(0, 0, 0, .50);\n  height: 100%;\n  margin: 0px;\n  overflow-y: scroll;\n  padding: 0px;\n  place-self: center;\n  position: absolute;\n  top: 0px;\n  width: 100%;\n}\n\n._7Xo4ho6XtV1lk6_LmG-PC {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  margin: auto;\n  max-width: 65vh;\n  padding-top: 64px;\n  width: 100%;\n}\n\n.pQsIRDH8shbgVBqGzmtnK {\n  display: block;\n  -webkit-box-flex: 0;\n      -ms-flex: 0 0 auto;\n          flex: 0 0 auto;\n  height: 100%;\n  margin: 8px;\n  width: calc(25% - 16px);\n}\n", ""]);
// Exports
exports.locals = {
	"colorPickerContainer": "_23iRLS1Ry0WuAwnFBuUz7y",
	"colorPicker": "_7Xo4ho6XtV1lk6_LmG-PC",
	"colorButton": "pQsIRDH8shbgVBqGzmtnK"
};
module.exports = exports;
