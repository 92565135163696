// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Mobile max: 767\n * Tablet max: 991\n */\n\n.Pe2803yuzW2BtvWPcsSC_ {\n  margin: auto;\n}\n\n@media (min-width: 768px) and (min-aspect-ratio: 3/4) {\n  .Pe2803yuzW2BtvWPcsSC_ {\n    max-width: 75vh;\n  }\n}\n\n._1IAFADYBYzkHwICERqwoD- {}\n\n._37J2UgAvmV_lG5POHjLQP0 {\n  margin: 8px;\n  padding: 8px;\n}\n\n._2PEIm6DTvHgJdXVZ9fGRhY {\n  margin: 8px !important;\n}\n\n.MqNX1U0rjGfuHuEL0nBar {\n  margin: 8px !important;\n  display: block;\n}\n\n.b8OHRLpRb6lbq3jXdEE8H {\n  margin: 8px !important;\n  width: calc(100% - 16px) !important;\n}\n\n._4LxkK9C3ruoKixzaDNdXl {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: reverse;\n      -ms-flex-direction: row-reverse;\n          flex-direction: row-reverse;\n}\n\n.i8XJ708z4X5HUX3qd7Wd5 {\n  margin: 8px !important;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "Pe2803yuzW2BtvWPcsSC_",
	"email": "_1IAFADYBYzkHwICERqwoD-",
	"paper": "_37J2UgAvmV_lG5POHjLQP0",
	"title": "_2PEIm6DTvHgJdXVZ9fGRhY",
	"caption": "MqNX1U0rjGfuHuEL0nBar",
	"text": "b8OHRLpRb6lbq3jXdEE8H",
	"buttonContainer": "_4LxkK9C3ruoKixzaDNdXl",
	"button": "i8XJ708z4X5HUX3qd7Wd5"
};
module.exports = exports;
