// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Mobile max: 767\n * Tablet max: 991\n */\n\n._2DlXSwXfK67vI_fdVjksDk {\n  margin: auto;\n}\n\n@media (min-width: 768px) and (min-aspect-ratio: 3/4) {\n  ._2DlXSwXfK67vI_fdVjksDk {\n    max-width: 75vh;\n  }\n}\n\n._21gFEMK8vBZsW7bYC1f8Hw {\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-flex: 1;\n      -ms-flex: 1 1;\n          flex: 1 1;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  margin-bottom: 0px;\n  margin-top: 8px;\n  margin: auto;\n  width: 100%;\n}\n\n._2GZhsELevnybfi0y7Oxov5 {\n  -webkit-box-flex: 0;\n      -ms-flex: 0 0 auto;\n          flex: 0 0 auto;\n}\n\n@media (max-width: 767px) {\n  ._2GZhsELevnybfi0y7Oxov5 {\n    -ms-flex-item-align: stretch;\n        align-self: stretch;\n  }\n}\n\n._1AvUm6pHvdaL-N_VvszAhF {\n  text-align: center;\n}\n\n._1QQe69oybYD_RVXt7RjxZw {\n  -webkit-box-flex: 1;\n      -ms-flex: 1 1 0%;\n          flex: 1 1 0%;\n  margin: 8px;\n  padding-left: calc(100% - 16px);\n  position: relative;\n}\n\n._1kOilErr2_aYVCMMOeRAVQ {\n  bottom: 0px;\n  left: 0px;\n  position: absolute;\n  right: 0px;\n  top: 0px;\n}\n\n.VQ23eWepTj7hev4A_GIop {\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  width: 100%;\n  height: 100%;\n}\n\n._1kj8aGeutacSmNfEpMeXFM {\n  -webkit-box-flex: 0;\n      -ms-flex: 0 0 auto;\n          flex: 0 0 auto;\n}\n\n@media (max-width: 767px) {\n  ._1kj8aGeutacSmNfEpMeXFM {\n    -ms-flex-item-align: stretch;\n        align-self: stretch;\n  }\n}\n\n._28O0sXJ91hys2CFU6YD3lE {\n  -webkit-box-pack: space-evenly;\n      -ms-flex-pack: space-evenly;\n          justify-content: space-evenly;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_2DlXSwXfK67vI_fdVjksDk",
	"drawingReplyContainer": "_21gFEMK8vBZsW7bYC1f8Hw",
	"labelCard": "_2GZhsELevnybfi0y7Oxov5",
	"labelTypography": "_1AvUm6pHvdaL-N_VvszAhF",
	"drawingWrapper": "_1QQe69oybYD_RVXt7RjxZw",
	"drawingContainer": "_1kOilErr2_aYVCMMOeRAVQ",
	"drawing": "VQ23eWepTj7hev4A_GIop",
	"actionsCard": "_1kj8aGeutacSmNfEpMeXFM",
	"actions": "_28O0sXJ91hys2CFU6YD3lE"
};
module.exports = exports;
