// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1ygmoBrVDc11YfBuJ2bwSA {\n  padding: 8px;\n  width: auto;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: reverse;\n      -ms-flex-direction: row-reverse;\n          flex-direction: row-reverse;\n}\n\n._3zDnBWmIOprMpbcLqMhbxG {\n  background-color: rgba(255, 255, 255, .1);\n  vertical-align: middle;\n  padding: 4px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  border-radius: 4px;\n  width: 80px;\n  -webkit-transition: width .25s ease-in-out;\n  transition: width .25s ease-in-out;\n}\n\n._3zDnBWmIOprMpbcLqMhbxG input {\n  vertical-align: middle;\n  color: white;\n  margin: 4px;\n  padding: 0px;\n}\n\n._3zDnBWmIOprMpbcLqMhbxG:focus-within {\n  width: 100%;\n}\n\n._3zDnBWmIOprMpbcLqMhbxG div::before {\n  display: none;\n}\n\n._3zDnBWmIOprMpbcLqMhbxG div::after {\n  display: none;\n}\n\n._3zDnBWmIOprMpbcLqMhbxG div {\n  margin: 0px;\n}\n\n._3zDnBWmIOprMpbcLqMhbxG svg {\n  vertical-align: middle;\n}\n", ""]);
// Exports
exports.locals = {
	"historySearchContainer": "_1ygmoBrVDc11YfBuJ2bwSA",
	"historySearch": "_3zDnBWmIOprMpbcLqMhbxG"
};
module.exports = exports;
