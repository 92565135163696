// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Mobile max: 767\n * Tablet max: 991\n */\n\n._2m3kSazAO-D08kCEKjhTPA {\n  margin: auto;\n}\n\n@media (min-width: 768px) and (min-aspect-ratio: 3/4) {\n  ._2m3kSazAO-D08kCEKjhTPA {\n    max-width: 75vh;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_2m3kSazAO-D08kCEKjhTPA"
};
module.exports = exports;
