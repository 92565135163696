// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Mobile max: 767\n * Tablet max: 991\n */\n\n._3YAlejrodyBNtPnC2aM0J6 {\n  margin: auto;\n}\n\n@media (min-width: 768px) and (min-aspect-ratio: 3/4) {\n  ._3YAlejrodyBNtPnC2aM0J6 {\n    max-width: 75vh;\n  }\n}\n\n._9hVqBH3jQFDmEXIjY7lZG {\n  -webkit-box-align: stretch;\n      -ms-flex-align: stretch;\n          align-items: stretch;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  margin-top: 16px;\n}\n\n._384YfW9KJ6RBm9J15Jv5BB {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -ms-flex-wrap: wrap-reverse;\n      flex-wrap: wrap-reverse;\n}\n\n._2pIA1_3xQs-w9aZP0Vs0ii {\n  -webkit-box-flex: 1;\n      -ms-flex-positive: 1;\n          flex-grow: 1;\n  -ms-flex-negative: 0;\n      flex-shrink: 0;\n  margin-left: auto;\n  margin-right: auto;\n  width: 100%;\n}\n\n@media (min-width: 768px) and (min-aspect-ratio: 3/4) {\n  ._2pIA1_3xQs-w9aZP0Vs0ii {\n    max-width: 75vh;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_3YAlejrodyBNtPnC2aM0J6",
	"contacts": "_9hVqBH3jQFDmEXIjY7lZG",
	"listContainerParent": "_384YfW9KJ6RBm9J15Jv5BB",
	"listContainer": "_2pIA1_3xQs-w9aZP0Vs0ii"
};
module.exports = exports;
