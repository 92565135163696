// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Mobile max: 767\n * Tablet max: 991\n */\n\n._1CvRgYAvqRY7dMR2zLK5Cz {\n  margin: auto;\n}\n\n@media (min-width: 768px) and (min-aspect-ratio: 3/4) {\n  ._1CvRgYAvqRY7dMR2zLK5Cz {\n    max-width: 75vh;\n  }\n}\n\n._3EKbDQF1Xvge0RFFuJJS67 {\n  margin: 8px;\n  padding: 8px;\n}\n\n._105uxxeq-vXX-381upT993 {\n  margin: 8px !important;\n}\n\n._3tOlbctFXzhKw4wnZEsP2Q {\n  margin: 8px !important;\n  display: block;\n}\n\n._3QyCynBctM8TC8puFUTuoD {\n  margin: 8px !important;\n  width: calc(100% - 16px) !important;\n}\n\n.__CNtWDGdn8fYmESN7cIc {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: reverse;\n      -ms-flex-direction: row-reverse;\n          flex-direction: row-reverse;\n}\n\n._1ERJ-uKXHEsp-f6dYSndcu {\n  margin: 8px !important;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_1CvRgYAvqRY7dMR2zLK5Cz",
	"paper": "_3EKbDQF1Xvge0RFFuJJS67",
	"title": "_105uxxeq-vXX-381upT993",
	"caption": "_3tOlbctFXzhKw4wnZEsP2Q",
	"text": "_3QyCynBctM8TC8puFUTuoD",
	"buttonContainer": "__CNtWDGdn8fYmESN7cIc",
	"button": "_1ERJ-uKXHEsp-f6dYSndcu"
};
module.exports = exports;
