// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Mobile max: 767\n * Tablet max: 991\n */\n\n._3ouloTgJhVkAHAq69dUX-U {\n  margin: auto;\n}\n\n@media (min-width: 768px) and (min-aspect-ratio: 3/4) {\n  ._3ouloTgJhVkAHAq69dUX-U {\n    max-width: 75vh;\n  }\n}\n\n._3ouloTgJhVkAHAq69dUX-U {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  padding: 0px;\n  margin: auto;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}\n\n._2A3RJQYdH669U7dBlMjBv_ {\n  width: calc(33% - 2px);\n  margin-top: 10px;\n  margin-bottom: 8px;\n  padding-top: 8px;\n  padding-bottom: 8px;\n}\n\n._1Q2fzj5M5Lim15TrnkolwG {\n  width: 100%;\n  margin-bottom: 8px;\n  padding-top: 8px;\n  padding-bottom: 8px;\n}\n\n._3F9LeHa-YaDxPyS8eSbZ9K {\n  width: 100%;\n  margin-bottom: 8px;\n  padding-top: 8px;\n  padding-bottom: 8px;\n}\n\n.Ds1i4ZFZDbkeoPlcc8FIW {\n  margin-bottom: 8px;\n  padding: 2px;\n  width: 100%;\n  overflow-x: auto;\n}\n\n._3dOD16jBDq9qYvhe91H3SY {\n  display: block;\n}\n\n@media (min-width: 768px) and (min-aspect-ratio: 3/4) {\n  ._3ouloTgJhVkAHAq69dUX-U {\n    max-width: 80%;\n  }\n\n  ._1Q2fzj5M5Lim15TrnkolwG {\n    width: calc(50% - 2px);\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_3ouloTgJhVkAHAq69dUX-U",
	"gameStat": "_2A3RJQYdH669U7dBlMjBv_",
	"gameSizes": "_1Q2fzj5M5Lim15TrnkolwG",
	"gamesOverTime": "_3F9LeHa-YaDxPyS8eSbZ9K",
	"userStat": "Ds1i4ZFZDbkeoPlcc8FIW",
	"caption": "_3dOD16jBDq9qYvhe91H3SY"
};
module.exports = exports;
